import React, { useEffect, useState } from "react"
import { Link } from "gatsby" // Import Link from gatsby
import styles from "./Speed.module.scss" // Import the SCSS file

const Speed = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    // Lazy load the 'speedlify-score' script
    import("./speedlify-score").then(() => setIsLoaded(true))
  }, [])

  return (
    <div className={styles.speedlify}>
      {isLoaded && (
        <>
          <Link
            className={styles.speedlifySeelay}
            to="https://speed.seelay.in/seelay-art/"
            target="_blank"
          >
            <div className={styles.pagespeed}>
              <img
                src="https://www.gstatic.com/pagespeed/insights/ui/logo/favicon_48.png"
                alt="Pagespeed Insights logo"
                width="18"
                height="18"
                className={styles.pagespeedimg}
              />
            </div>
            <speedlify-score
              speedlify-url="https://speed.seelay.in/"
              hash="95f8cc7c"
              title="Results after 15/11/2024"
              style={{ "--speedlify-circle": "#4CAF50" }}
            />
          </Link>
        </>
      )}
    </div>
  )
}

export default Speed
